import {
  getCompanyProfileById,
  getJobDescription,
  getCompanyProfile,
  getIPInfo,
  getShortenCompanyUrl,
  getShortenJobDescriptionUrl,
  getAppliedJobsList,
  getNumberOfQuestions,
  getCompanyProfileJobs,
  getFullApplyForm,
  getUploadedResumeFile,
  getIndustries,
  postJobDescriptionQuickApplyForm,
  postRegisterApplicant,
  postLoginApplicant,
  postResetPasswordApplicant,
  postNewPasswordApplicant,
  postNewFullApplyForm,
  postFullApplyFormQuestions,
  postFullApplyFormQuestionsAutosave,
  postFullApplyFormPersonalQuestionsAutosave,
  postFullApplyFormResume,
  postLicenseVerificationData,
  getLicenseVerificationData,
  postVerificationFileUpload,
  getCredentialingStateFullName,
  postStreamlineVerificationData,
} from '../api/api';
import {
  saveCompanyProfile,
  saveIpInfo,
  saveShortenUrl,
  saveMoreCompanyProfileJobs,
  saveCompanyProfileJobs,
} from './companyProfile_actions';
import {
  saveJobDescription,
  saveShortenJobDescriptionUrl,
  saveNumberOfQuestions,
} from './jobDescription_actions';
import {
  saveFullApplyForm,
  saveFullApplyFormPersonalData,
  saveSpecificAnswers,
  saveUploadedResumeFile,
} from './jobApplication_actions';
import { saveIndustries, setLoading } from './global_actions';
import { saveUserData } from './user_actions';
import { saveAuthenticationData } from './authentication_actions';
import { saveLicenseVerificationData } from './licenseVerification_actions';
import { fixUploadedFileDate } from '../helperFunctions/helperFunctions';
import { logApiError } from '../../util';

/* GET REQUEST */
/* ======================== */
export const getIndustriesAction =
  (environment, city, state, location, radius) => (dispatch) =>
    getIndustries(environment, city, state, location, radius)
      .then((res) => dispatch(saveIndustries(res?.data)))
      .catch((error) => {
        logApiError('FETCH INDUSTRIES', error);
      });

export const getCompanyProfileAction = (environment, routeSlug) => (dispatch) =>
  getCompanyProfile(environment, routeSlug)
    .then((res) => dispatch(saveCompanyProfile(res)))
    .catch((error) => {
      logApiError('FETCH COMPANY PROFILE', error);
    });

export const getCompanyProfileByIdAction =
  (environment, routeSlug) => (dispatch) =>
    getCompanyProfileById(environment, routeSlug)
      .then((res) => dispatch(saveCompanyProfile(res)))
      .catch((error) => {
        logApiError('FETCH COMPANY PROFILE BY ID', error);
      });

export const getJobDescriptionAction =
  (environment, routeSlug, protocol, hostname, interviewGuide) => (dispatch) =>
    getJobDescription(
      environment,
      routeSlug,
      protocol,
      hostname,
      interviewGuide,
    )
      .then((res) => dispatch(saveJobDescription(res)))
      .catch((error) => {
        console.log(error)
        logApiError('FETCH JOB DESCRIPTION', error);
      });

export const getIPInfoAction = (environment) => (dispatch) =>
  getIPInfo(environment)
    .then((res) => {
      dispatch(saveIpInfo(res));
    })
    .catch((error) => {
      logApiError('FETCH IP INFO', error);
    });

export const getShortenCompanyUrlAction =
  (environment, routeSlug, protocol, hostname) => (dispatch) =>
    getShortenCompanyUrl(environment, routeSlug, protocol, hostname)
      .then((res) =>
        dispatch(
          saveShortenUrl({
            website: res.website.data.data.shortUrl,
            facebook: res.facebook.data.data.shortUrl,
            twitter: res.twitter.data.data.shortUrl,
            linkedin: res.linkedin.data.data.shortUrl,
          }),
        ),
      )
      .catch((error) => {
        logApiError('FETCH SHORTEN JOB DESCRIPTION URL', error);
      });

export const getShortenJobDescriptionUrlAction =
  (environment, routeSlug, protocol, hostname) => (dispatch) =>
    getShortenJobDescriptionUrl(environment, routeSlug, protocol, hostname)
      .then((res) =>
        dispatch(
          saveShortenJobDescriptionUrl({
            website: res.website.data.data.shortUrl,
            facebook: res.facebook.data.data.shortUrl,
            twitter: res.twitter.data.data.shortUrl,
            linkedin: res.linkedin.data.data.shortUrl,
          }),
        ),
      )
      .catch((error) => {
        logApiError('FETCH SHORTEN JOB DESCRIPTION URL', error);
      });

export const getCompanyProfileJobsAction =
  (
    environment,
    page,
    searchbar,
    latitude,
    longitude,
    teams,
    filteredIndustries,
    backfilledJobs,
    debug,
    language,
    city,
    state,
    radius,
    utm,
  ) =>
    (dispatch) => {
      dispatch(setLoading(true));
      return getCompanyProfileJobs(
        environment,
        page,
        searchbar,
        latitude,
        longitude,
        teams,
        filteredIndustries,
        backfilledJobs,
        debug,
        language,
        city,
        state,
        radius,
        utm,
      )
        .then((res) => {
          if (res) {
            dispatch(setLoading(false));
            /* If page is bigger then 1 that means we are scrolling and loading new jobs */
            if (res.type === 'saveMoreCompanyProfileJobs') {
              return dispatch(saveMoreCompanyProfileJobs(res.data.data.data));
            }
            /* If page is 1 that means its the first load for that location, keyword, page load... */
            if (res.type === 'saveCompanyProfileJobs') {
              return dispatch(saveCompanyProfileJobs(res.data.data.data));
            }
          } else {
            /* If page is bigger then 1 and there is no data that means there are no more
          jobs to load so we just return */
            dispatch(setLoading(false));
            return false;
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          logApiError('FETCH APPLIED JOBS LIST', error);
        });
    };

export const getNumberOfQuestionsAction =
  (environment, routeSlug, token) => (dispatch) =>
    getNumberOfQuestions(environment, routeSlug, token)
      .then((res) => dispatch(saveNumberOfQuestions(res)))
      .catch((error) => {
        logApiError('FETCH NUMBER OF QUESTIONS', error);
      });

export const getAppliedJobsListAction =
  (environment, routeSlug, applicantId, interviewGuide, token) => (dispatch) =>
    getAppliedJobsList(
      environment,
      routeSlug,
      applicantId,
      interviewGuide,
      token,
    )
      .then((res) => dispatch(saveUserData(res)))
      .catch((error) => {
        logApiError('FETCH APPLIED JOBS LIST DIS', error);
      });

export const getFullApplyFormAction =
  (environment, routeSlug, applyFormId, token, interviewGuide, utmData) =>
    (dispatch) =>
      getFullApplyForm(
        environment,
        routeSlug,
        applyFormId,
        token,
        interviewGuide,
        utmData,
      )
        .then((response) => {
          Object.keys(response.answers).forEach((answerKey) => {
            const answerFileDateCreated =
              response.answers[answerKey]?.uploaded_file?.date_created;
            if (answerFileDateCreated) {
              response.answers[answerKey].uploaded_file.date_created =
                fixUploadedFileDate(answerFileDateCreated);
            }
          });
          dispatch(saveFullApplyForm(response));
          return response;
        })
        .catch((error) => {
          logApiError('FETCH GET FULL APPLY FORM', error);
          throw error;
        });

export const getUploadedResumeFileAction =
  (environment, applicantId, jobId, token) => (dispatch) =>
    getUploadedResumeFile(environment, applicantId, jobId, token)
      .then((response) => {
        dispatch(saveUploadedResumeFile(response));
        return response;
      })
      .catch((error) => {
        logApiError('FETCH GET UPLOADED RESUME FILE', error);
      });

export const getLicenseVerificationDataAction =
  (environment, personId) => (dispatch) =>
    getLicenseVerificationData(environment, personId)
      .then((response) => {
        dispatch(saveLicenseVerificationData(response));
        return response;
      })
      .catch((error) => {
        logApiError('FETCH LICENSE VERIFICATION DATA', error);
        throw error;
      });

export const getCredentialingStateFullNameAction =
  (environment, state, type) => (dispatch) =>
    getCredentialingStateFullName(environment, state, type)
      .then((response) => response)
      .catch((error) => {
        logApiError('FETCH CREDENTIALING STATE FULL NAME', error);
        if (typeof window !== 'undefined') {
          window.location.replace('/verification/state-role-not-supported');
        }
      });

/* POST REQUEST */
/* ======================== */

export const postNewFullApplyFormAction =
  (environment, jobId, token, utmData, interviewGuide) => (dispatch) =>
    postNewFullApplyForm(environment, jobId, token, utmData, interviewGuide)
      .then((response) => {
        dispatch(saveFullApplyForm(response));
        return response;
      })
      .catch((error) => {
        logApiError('FETCH POST NEW FULL APPLY FORM', error);
        throw error.response.data;
      });

export const postJobDescriptionQuickApplyFormAction =
  (environment, easyApplyData, jobId) => (dispatch) =>
    postJobDescriptionQuickApplyForm(environment, easyApplyData, jobId)
      .then((easyApplyApplicant) => {
        dispatch(
          saveAuthenticationData(easyApplyApplicant, 'easyApplyApplicant'),
        );
        return easyApplyApplicant;
      })
      .catch((error) => {
        logApiError('FETCH POST JOB DESCRIPTION QUICK APPLY FORM', error);
        throw error.response.data.developerMessage;
      });

export const postRegisterApplicantAction =
  (environment, email, password) => (dispatch) =>
    postRegisterApplicant(environment, email, password)
      .then((authenticationData) => {
        dispatch(saveAuthenticationData(authenticationData, 'applicant'));
        return authenticationData;
      })
      .catch((error) => {
        logApiError('FETCH POST REGISTER APPLICANT', error);
        throw error.response.data;
      });

export const postLoginApplicantAction =
  (environment, email, password, token) => (dispatch) =>
    postLoginApplicant(environment, email, password, token)
      .then((authenticationData) => {
        dispatch(saveAuthenticationData(authenticationData, 'applicant'));
        return authenticationData;
      })
      .catch((error) => {
        logApiError('FETCH POST LOGIN APPLICANT', error);
        throw error.response.data;
      });

export const postResetPasswordApplicantAction =
  (environment, email) => (dispatch) =>
    postResetPasswordApplicant(environment, email)
      .then((response) => response)
      .catch((error) => {
        logApiError('FETCH POST RESET PASSWORD APPLICANT', error);
        throw error.response.data;
      });

export const postNewPasswordApplicantAction =
  (environment, newpassword, token) => (dispatch) =>
    postNewPasswordApplicant(environment, newpassword, token)
      .then((authenticationData) => {
        dispatch(saveAuthenticationData(authenticationData, 'applicant'));
        return authenticationData;
      })
      .catch((error) => {
        logApiError('FETCH POST NEW PASSWORD APPLICANT', error);
        throw error.response.data;
      });

export const postFullApplyFormQuestionsAutosaveAction =
  (environment, answers, id, token, interviewGuide) => (dispatch) =>
    postFullApplyFormQuestionsAutosave(
      environment,
      answers,
      id,
      token,
      interviewGuide,
    )
      .then((response) => {
        dispatch(saveSpecificAnswers(response));
        return response;
      })
      .catch((error) => {
        logApiError('FETCH POST FULL APPLY FORM QUESTIONS AUTOSAVE', error);
        throw error.response.data;
      });

export const postFullApplyFormPersonalQuestionsAutosaveAction =
  ({ environment, personalAnswers, id, token }) =>
    (dispatch) =>
      postFullApplyFormPersonalQuestionsAutosave({
        environment,
        personalAnswers,
        id,
        token,
      })
        .then((response) => {
          dispatch(
            saveAuthenticationData(response.authenticationData, 'applicant'),
          );
          dispatch(saveFullApplyFormPersonalData(response.fullResponse));
          return response;
        })
        .catch((error) => {
          logApiError('FETCH POST FULL APPLY FORM PERSONAL QUESTIONS AUTOSAVE', error);
          throw error.response.data;
        });

export const postFullApplyFormQuestionsAction =
  (environment, id, token, interviewGuide) => (dispatch) =>
    postFullApplyFormQuestions(environment, id, token, interviewGuide)
      .then((response) => response)
      .catch((error) => {
        logApiError('FETCH POST FULL APPLY FORM QUESTIONS', error);
        throw error.response.data;
      });

export const postFullApplyFormResumeAction =
  (environment, formFile, token) => (dispatch) =>
    postFullApplyFormResume(environment, formFile, token)
      .then((response) => {
        dispatch(
          saveAuthenticationData(response.authenticationData, 'applicant'),
        );
        dispatch(saveFullApplyFormPersonalData(response.fullResponse));
        return response;
      })
      .catch((error) => {
        logApiError('FETCH POST FULL APPLY FORM RESUME', error);
        throw error.response.data;
      });

export const postLicenseVerificationDataAction =
  (
    environment,
    personId,
    state,
    jobId,
    verificationType,
    licenseNumber,
    ssnNumber,
    birthYear,
    rudderstackFields,
  ) =>
    (dispatch) =>
      postLicenseVerificationData(
        environment,
        personId,
        state,
        jobId,
        verificationType,
        licenseNumber,
        ssnNumber,
        birthYear,
        rudderstackFields,
      )
        .then((response) => {
          dispatch(saveLicenseVerificationData(response));
          return response;
        })
        .catch((error) => {
          logApiError('FETCH POST VERIFICATION DATA', error);
          throw error;
        });

export const postVerificationFileUploadAction =
  (environment, fileData) => (dispatch) =>
    postVerificationFileUpload(environment, fileData)
      .then((response) => response)
      .catch((error) => {
        logApiError('FETCH POST VERIFICATION FILE UPLOAD', error);
        throw error.response.data;
      });

export const postStreamlineVerificationDataAction =
  ({
    environment,
    personId,
    state,
    jobId,
    verificationType,
    rudderstackFields,
    streamlineFields,
  }) =>
    (dispatch) =>
      postStreamlineVerificationData({
        environment,
        personId,
        state,
        jobId,
        verificationType,
        streamlineFields,
        rudderstackFields,
      })
        .then((response) => {
          dispatch(saveLicenseVerificationData(response));
          return response;
        })
        .catch((error) => {
          console.error('ERROR FETCH POST VERIFICATION DATA', error);
          return Promise.reject(error);
        });
